<style scoped lang="scss">
  .lostnode_data_box {
    .toolbars {
      .otherbar {
        float: right;
        .selectservicer,.searchbtn  {
          display: inline-block;
        }
      }
    }
    .echarts {
      float: left;
      margin: 0 auto 20px;
    }
    .clearb {
      margin-bottom: 15px;
    }
  }
</style>

<template>
  <div class="lostnode_data_box">
    <div class="toolbars">
      <div class="otherbar">
        <date-time @commitPicker="getPicker" class="mrgr5 mrgb5"></date-time>
        <el-button class="searchbtn mrgb5" type="primary" plain size="small" @click="_search"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>
    <div class="clearb"></div>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12">
        <echart id="levelLost" title="等级" class="echarts" width="100%" height="400px" :option="options1"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="customsEchart" title="关卡" class="echarts" width="100%" height="400px" :option="options2"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="guideLost" title="引导" class="echarts" width="100%" height="400px" :option="options3"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="playMethodEchart" title="玩法" class="echarts" width="100%" height="400px" :option="options4"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="expeditionEchart" title="远征" class="echarts" width="100%" height="400px" :option="options5"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="sexEchart" title="性别" class="echarts" width="100%" height="400px" :option="options6"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="vipLevelEchart" title="Vip等级" class="echarts" width="100%" height="400px" :option="options7"></echart>
      </el-col>
      <el-col :xs="24" :sm="12">
        <echart id="appointEchart" title="埋点" class="echarts" width="100%" height="400px" :option="options8"></echart>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import dateTime from "@/components/DateTime"
import echart from "@/components/Echarts/Index";
export default {
  name: "lossNode",
  components: {
    dateTime,
    echart
  },
  data() {
    return {
      options1: {},
      options2: {},
      options3: {},
      options4: {},
      options5: {},
      options6: {},
      options7: {},
      options8: {},
      listQuery: {
        serverId: null,
        beginTime: null,
        endTime: null
      }
    };
  },
  mounted() {
    this.setOptions1();
    this.setOptions2();
    this.setOptions3();
    this.setOptions4();
    this.setOptions5();
    this.setOptions6();
    this.setOptions7();
    this.setOptions8();
  },
  methods: {
    setOptions1() {
      this.options1 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["Lv5", "Lv10", "Lv15", "Lv20", "Lv30", "Lv50", "Lv100"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [80, 99, 22, 5, 14, 38, 66]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [12, 32, 8, 84, 77, 95, 48]
          }
        ]
      }
    },
    setOptions2() {
      this.options2 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["关卡1", "关卡5", "关卡8", "关卡10", "关卡20", "关卡30", "关卡50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [15, 32, 72, 85, 24, 68, 96]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions3() {
      this.options3 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["引导1", "引导2", "引导8", "引导10", "引导20", "引导30", "引导50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [15, 32, 72, 85, 24, 68, 96]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions4() {
      this.options4 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["玩法1", "玩法2", "玩法8", "玩法10", "玩法20", "玩法30", "玩法50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [66, 33, 44, 22, 55, 11, 99]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions5() {
      this.options5 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["远征1", "远征2", "远征8", "远征10", "远征20", "远征30", "远征50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [66, 33, 44, 22, 55, 11, 99]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions6() {
      this.options6 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["男性", "女性"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [66, 33, 44, 22, 55, 11, 99]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [88, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions7() {
      this.options7 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["vip1", "vip2", "vip8", "vip10", "vip20", "vip30", "vip50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [66, 33, 44, 22, 55, 11, 99]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    setOptions8() {
      this.options8 = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["流失玩家数量", "流失玩家比例"]
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: ["埋点1", "埋点2", "埋点8", "埋点10", "埋点20", "埋点30", "埋点50"]
        },
        yAxis: [
          {
            name: '数量',
            type: 'value'
          },
          {
              name: '比例',
              nameLocation: 'end',
              type: 'value',
              axisLabel: {
                formatter: '{value}%'
              }
          }
        ],
        series: [
          {
            name: "流失玩家数量",
            type: "bar",
            stack: "count",
            barWidth: "20%",
            barMaxWidth: "20px",
            data: [66, 33, 44, 22, 55, 11, 99]
          },
          {
            name: "流失玩家比例",
            yAxisIndex:1,
            type: "line",
            stack: "ratio",
            data: [8, 74, 66, 55, 48, 31, 29]
          }
        ]
      }
    },
    getPicker(val) {
      this.listQuery.beginTime = val[0]
      this.listQuery.endTime = val[1]
    },
    _search() {
      
    }
  }
}
</script>